<template>
  <div class="editorjs" id="editorjs" ref="editorjs"></div>
</template>

<script>
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Delimiter from '@editorjs/delimiter';
import ImageTool from '@editorjs/image';
import MaterialService from '@/services/material.service';
import i18nOptionsMixin from '@/mixins/editorJsI18n.mixin';
import ListLMS from '@/utils/EditorJsLMSList';
import QuoteLms from '@/utils/EditorJsLMSQuote';
import ParagraphLms from '@/utils/EditorJsLMSParagraph';

export default {
  name: 'EditorJsComponent',
  props: {
    initialContent: {
      type: Object,
      default: () => ({}),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [i18nOptionsMixin],
  data: () => ({
    editor: null,
    contentData: null,
  }),
  mounted() {
    this.editor = new EditorJS({
      holder: this.$refs.editorjs,
      data: this.initialContent,
      onChange: this.onChange,
      onReady: this.onReady,
      i18n: this.i18nOptions,
      tools: {
        paragraph: {
          class: ParagraphLms,
          inlineToolbar: true,
          config: {
            courserId: this.$route.params.courseId,
          },
        },
        header: {
          class: Header,
          inlineToolbar: true,
          config: {
            placeholder: '',
            levels: [1, 2, 3, 4, 5],
            defaultLevel: 3,
          },
        },
        list: {
          class: ListLMS,
          inlineToolbar: true,
        },
        quote: {
          class: QuoteLms,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+O',
          config: {
            quotePlaceholder: this.$t('placeholders.enterQuote'),
            captionPlaceholder: this.$t('placeholders.quoteAuthor'),
          },
        },
        delimiter: Delimiter,
        image: {
          class: ImageTool,
          config: {
            uploader: {
              /**
               * Upload file to the server and return an uploaded image data
               * @param {File} file - file selected from the device or pasted by drag-n-drop
               * @return {Promise.<{success, file: {url}}>}
               */
              uploadByFile(file) {
                const getFormData = () => {
                  const formData = new FormData();
                  formData.append('file', file);
                  formData.append('type', file.type);
                  return formData;
                };
                return MaterialService.uploadCover(getFormData(file)).then(({ data }) => ({
                  success: 1,
                  file: {
                    url: `https://${data.imageHighres}`,
                  },
                }));
              },
            },
            types: 'image/png, image/jpg, image/jpeg,',
          },
        },
      },
      logLevel: 'ERROR',
      readOnly: this.readOnly,
      placeholder: !this.readOnly ? this.$t('placeholders.enterDescription') : '',
    });
  },
  methods: {
    onReady() {
      if (this.editor.toolbar && !this.readOnly) {
        this.editor.toolbar.open();
      }
    },
    onChange(api) {
      api.saver
        .save()
        .then((v) => {
          this.$emit('change', v);
        })
        .catch((error) => {
          console.log('Saving failed: ', error);
        });
    },
  },
  beforeDestroy() {
    if (this.editor && this.editor?.destroy) {
      this.editor.destroy();
    }
  },
};
</script>
