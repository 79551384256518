<template>
  <b-badge
    class="b-form-tag tags-select__item"
    :class="{
      secondary: variant === 'secondary',
      'user-select-none': !isEditable,
      'tags-select__item--inverse': isEditable && editing,
    }"
    @click="handleClick"
  >
    <div v-if="!!this.$slots['prefixIcon']" class="prefixIcon" @click.stop="$emit('clickPrefix')" >
      <slot name="prefixIcon" />
    </div>
    <span @keypress="filterInput" @blur="onBlur" ref="badge" :contenteditable="isEditable">
      {{ title }}
    </span>
    <div v-if="!!this.$slots['suffixIcon']" class="suffixIcon" @click.stop="$emit('clickSuffix')">
      <slot name="suffixIcon" />
    </div>
  </b-badge>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    tag: {
      type: Object,
      default: null,
    },
    update: {
      type: Function,
      default: () => Promise.resolve,
    },
    title: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    editing: false,
  }),
  methods: {
    ...mapActions('toaster', ['setToaster']),
    handleClick(e) {
      if (e.target !== this.$refs.badge) {
        this.$refs.badge.blur();
      } else {
        this.editing = true;
      }
      this.$emit('click');
    },
    filterInput(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
      if (event.keyCode === 32 && !this.$refs.badge.innerText.trim()) {
        event.preventDefault();
      }
      if (this.$refs.badge.innerText.length > 63) {
        event.preventDefault();
      }
    },
    onBlur(event) {
      this.editing = false;
      const newValue = event.target.innerText.trim();
      this.$refs.badge.innerText = newValue;
      if (newValue !== this.title && this.tag) {
        this.update({ ...this.tag, name: newValue }).catch((e) => {
          const isExist = e?.response?.data?.error?.includes('already exist');
          this.setToaster({
            type: 'toast-warning',
            toast: {
              title: this.$t('errorMessages.tagError'),
              body: isExist
                ? this.$t('errorMessages.tagAlreadyExist')
                : this.$t('errorMessages.somethingWentWrong'),
            },
          });

          this.$nextTick(() => {
            this.$refs.badge.innerText = this.tag.name;
          });
        });
      }
    },
  },
};
</script>
