import CheckAccess from '@/utils/checkAccessDecorator';
import axios from '@/http';

class TagService {
  @CheckAccess
  appendTag = (programId, courseId, tag_name) => {
    if (programId && programId === 'self') {
      programId = 'activity';
    }
    return axios.post(`teacher/${programId}/${courseId}/tag/append`, { tag_name }).then(({ data }) => data);
  };

  @CheckAccess
  removeTagFromActivity = (programId, courseId, tag_id) => {
    if (programId && programId === 'self') {
      programId = 'activity';
    }
    return axios.post(`teacher/${programId}/${courseId}/tag/remove`, { tag_id }).then(({ data }) => data);
  };

  @CheckAccess
  getTagsByAuthor = (programId, courseId) => {
    if (programId && programId === 'self') {
      programId = 'activity';
    }
    return axios.get(`teacher/${programId}/${courseId}/tag/by_author`).then(({ data }) => data);
  };

  @CheckAccess
  getTagsByActivity = (programId, courseId) => {
    if (programId && programId === 'self') {
      programId = 'activity';
    }
    return axios.get(`teacher/${programId}/${courseId}/tag/by_activity`).then(({ data }) => data);
  };

  @CheckAccess
  updateTag = (tag) => axios.patch(`teacher/tag/${tag.id}`, { tag_name: tag.name }).then(({ data }) => data);

  @CheckAccess
  deleteTag = (id) => axios.delete(`teacher/tag/${id}`).then(({ data }) => data);

  @CheckAccess
  getTagsByStudent = (programId, courseId) => axios.get(`student/activity/${programId}/${courseId}/tag`).then(({ data }) => data);
}

export default new TagService();
