export default {
  computed: {
    i18nOptions() {
      return {
        messages: {
          ui: {
            blockTunes: {
              toggler: {
                'Click to tune': this.$t('editorjs.clickToTune'),
                'or drag to move': this.$t('editorjs.orDragToMove'),
              },
            },
            inlineToolbar: {
              converter: {
                'Convert to': this.$t('editorjs.convertTo'),
              },
            },
            toolbar: {
              toolbox: {
                Add: this.$t('editorjs.add'),
              },
            },
          },

          toolNames: {
            Text: this.$t('editorjs.text'),
            Heading: this.$t('editorjs.heading'),
            List: this.$t('editorjs.list'),
            Warning: this.$t('editorjs.warning'),
            Checklist: this.$t('editorjs.checklist'),
            Quote: this.$t('editorjs.quote'),
            Code: this.$t('editorjs.code'),
            Delimiter: this.$t('editorjs.delimiter'),
            'Raw HTML': this.$t('editorjs.rawHTML'),
            Table: this.$t('editorjs.table'),
            Link: this.$t('editorjs.link'),
            Marker: this.$t('editorjs.marker'),
            Bold: this.$t('editorjs.bold'),
            Italic: this.$t('editorjs.italic'),
            InlineCode: this.$t('editorjs.inlineCode'),
          },

          tools: {
            warning: {
              Title: this.$t('editorjs.title'),
              Message: this.$t('editorjs.message'),
            },
            link: {
              'Add a link': this.$t('editorjs.addALink'),
            },
            stub: {
              'The block can not be displayed correctly.': this.$t(
                'editorjs.theBlockCanNotBeDisplayedCorrectly',
              ),
            },
          },

          blockTunes: {
            delete: {
              Delete: this.$t('editorjs.delete'),
            },
            moveUp: {
              'Move up': this.$t('editorjs.moveUp'),
            },
            moveDown: {
              'Move down': this.$t('editorjs.moveDown'),
            },
          },
        },
      };
    },
  },
};
